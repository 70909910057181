import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  constructor() { }
  transform(value: string): any {
    if (value) {
      return parseFloat(value).toFixed(2).toString().replace(".", ",")
    } else {
      return ''
    }
  }

}
