/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponseBase } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorResourceStatus extends ResourceStatus {
  response?: HttpErrorResponse;
}

export class ResourceStatus implements ResourceStatus {
  message?: string;
  data?: any;
  context?: any;
  response?: HttpResponseBase;
  code?: string | number;
  type?: string[] | string;
  error?: Error;

  setMessage(message: string) {
    this.message = message;
    return this;
  }

  setData(data: any) {
    return this.setContext(data);
  }

  setContext(context: any) {
    this.context = context;
    return this;
  }

  setResponse(response: HttpResponseBase) {
    this.response = response;
    return this;
  }

  setCode(code: string | number) {
    this.code = code;
    return this;
  }

  setType(type?: string[] | string) {
    this.type = type;
    return this;
  }

  getError(){
    // TODO when we know what to expect implement
  }

}
