import { Injectable } from '@angular/core';
import { SidebarMenuService } from 'src/app/layout-components/sidebar-menu/sidebar-menu.service';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  private previousUrl: string | null = null;

  constructor(private sidebarMenuService: SidebarMenuService) {}

  public setPreviousUrl(url: string): void {
    const menuLinkList = this.sidebarMenuService.getMenuList().map(item => item.link);
    if (url !== '/main/leasing-calculator') {
      this.previousUrl = menuLinkList.includes(url) ? url : this.previousUrl;
    };
    console.log(this.previousUrl);
  }

  public getPreviousUrl(): string | null {
    return this.previousUrl;
  }

  public resetPreviousUrl(): void {
    this.previousUrl = null;
  }
}
