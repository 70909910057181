import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@coreservices/utils/app.constants';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/internal/operators';
import { User } from '../index';
import { AppConfigService } from '../app-config';
import { onResponseWithError, ResourceErrorPayload } from '../utils';
import { SstoreResponse } from '../utils/parser.utils';
import { JwtPayload, JwtToken, RegisterPayload, RegisterReponse } from './auth.models';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  login(payload: JwtPayload): Observable<JwtToken | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.LOGIN);

    return this.http.post(url, payload)
      .pipe(
        map((raw: SstoreResponse<JwtToken>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  getMe() {
    const url = this.appService.getUrl(API.ME);

    return this.http.get<SstoreResponse<User>>(url).pipe(
      map((raw: SstoreResponse<User>) => raw.data),
      catchError(onResponseWithError)
    );
  }

  getVersion() {
    const url = this.appService.getApiUrl(API.VERSION);

    return this.http.get<SstoreResponse<any>>(url).pipe(
      map((raw: SstoreResponse<any>) => raw),
      catchError(onResponseWithError)
    );
  }

  register(data: RegisterPayload): Observable<RegisterReponse | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.REGISTER);

    return this.http.post<SstoreResponse<RegisterReponse>>(url, data)
      .pipe(
        map((raw: SstoreResponse<RegisterReponse>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  resetPassword(data: any): Observable<any | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.CHANGE_PASSWORD);

    return this.http.post<any>(url, data);
  }

  forgotPassword(data: { username: string }): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl('user/password/reset/mail');

    return this.http.post<void>(url, data)
      .pipe(
        catchError(onResponseWithError)
      );
  }

  logOut(body: any, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.LOGOUT);
    return this.http.post<void>(url, body, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }

  constructor(private http: HttpClient, private appService: AppConfigService) {
  }
}
