import { DatePipe } from "@angular/common";

export interface SstoreResponse<T> {
  status: string;
  message: string;
  data?: T;
  posts?: T;
}

export const objLowerCaseParser = (obj: any) => {
  var key, keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {}
  while (n--) {
    key = keys[n];
    newobj[key.toLowerCase()] = obj[key];
  }
  return newobj;
};

export const sortArrayOfObjects = (arr, propertyName, order = 'descending') => {
  const sortedArr = arr.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return 1;
    }
    if (a[propertyName] > b[propertyName]) {
      return -1;
    }
    return 0;
  });
  if (order === 'ascending') {
    return sortedArr.reverse();
  }
  return sortedArr;
};

export const sortDate = (direction: any, a: string, b: string): number => {
  let first = Number(new DatePipe('en-US').transform(a, 'yyyyMMdd'));
  let second = Number(new DatePipe('en-US').transform(b, 'yyyyMMdd'));
  if (first < second) {
    return -1 * direction;
  }
  if (first > second) {
    return direction;
  }
  return 0;
}

export const sortAlphabet = (direction: any, a: any, b: any) => {
  var textA = a.toUpperCase();
  var textB = b.toUpperCase();
  return (textA < textB) ? -1 * direction : (textA > textB) ? 1 * direction : 0;
}

export const checkValidations = (form) => {
  const invalid = [];
  const controls = form.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  return invalid;
}