/* eslint-disable @typescript-eslint/no-shadow */
import { ErrorResourceStatus, ResourceStatus } from './resource-status';



export interface ResourcePayload<T, TStatus extends ResourceStatus> {
  data?: T;
  status?: TStatus;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResourceErrorPayload extends ResourcePayload<any, ErrorResourceStatus> {
}

export class Resource<T, TStatus extends ResourceStatus> {
  loading = false;
  loaded = false;
  hasError = false;

  constructor(public data?: T, public status?: TStatus) {
  }

  static getData<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>) {
    return resource.data;
  }

  static getError<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>) {
    return resource.hasError && resource.status;
  }

  static setLoading<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>, status?: TStatus) {
    const newResource = new Resource(resource.data, status || resource.status);
    newResource.loading = true;
    newResource.loaded = false;

    return newResource;
  }

  static reset<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>) {
    return new Resource<T, TStatus>();
  }

  static setData<T, TStatus extends ResourceStatus>(data: T, status?: TStatus) {
    const resource = new Resource<T, TStatus>(data, status);
    resource.loaded = true;
    return resource;
  }

  static setError<T, TStatus extends ResourceStatus>(error: TStatus) {
    const resource = new Resource<T, TStatus>(undefined, error);
    resource.loaded = true;
    resource.hasError = true;
    return resource;
  }

  static setSuccessPayload<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>, payload: ResourcePayload<T, TStatus>) {
    return Resource.setData<T, TStatus>(payload && payload.data as T, payload && payload.status);
  }

  static setFailedPayload<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>, payload: ResourcePayload<T, TStatus>) {
    return Resource.setError<T, TStatus>(payload && payload.status as TStatus);
  }

  static setLoadingPayload<T, TStatus extends ResourceStatus>(resource: Resource<T, TStatus>, payload?: ResourcePayload<T, TStatus>) {
    return Resource.setLoading<T, TStatus>(resource, payload && payload.status);
  }

}
