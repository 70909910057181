export const API = {
  LOGIN: 'user/login',
  REGISTER: 'user/register',
  CHANGE_PASSWORD: 'user/password/change',
  LOGOUT: 'user/logout',
  ME: 'user/me',
  VERSION: 'version',
  FILES: 'file',
  PRODUCT_GROUPS: 'product_group',
  EMAIL_TEXT: 'email_text',
  MARKETS: 'market',
  MERCHANTS: 'merchant',
  USERS: 'user',
  INTEREST_RATES: 'interest_rate',
  RECALCULATE_RATES: 'recalculate-rates',
  PRODUCTS: 'product',
  PRODUCTS_BY_MERCHANT_ID: 'getProductByMerchantId',
  SYSETM_LANGUAGES: 'system-language',
  INSTITUTES: 'eshop/institutes',
  CREATESESSION: 'CreateSession',
  ADDNEWPRODUCT: 'AddNewProduct',
  SENDMAIL : 'sendMail',
  FORMDATA : 'form',
  LANDINGPAGEMAIL : 'MailnewLandingPage',
  ACTIVITYREPORT: 'activities',
  REGISTERACTIVITY: 'register-activity',
  UPDATEQUANTITY: 'product/reduce-quantity',
  ALLPROCESS: 'processList?type=all',
  SYNCPROCESS: 'syncWithExtProduct',
  CANCELSYNCPROCESS: 'cancelSyncProcess',
  PROCESSSTATUS: 'processStatus',
  UPDATECOMBINATIONQUANTITY: 'product-combination/reduce-quantity',
  ASSIGNEDMARKETS: 'assigned-markets',
  ASSIGNEDMARKETSDISPLAYAPPROVAL: 'assigned-markets/display-approval',
  FOREIGNPRODUCTS : 'foreign-products',
  FOREIGNPRODUCTAPPROVAL: 'foreign-product-approval',
  GEORGESTOREADMINAPPROVAL: 'product/george-release-approval',
};

export const PRODUCT_MAX_PRICE_LIMIT = 5000;