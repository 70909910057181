import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { AppConfigService } from '../app-config';
import { ResourceErrorPayload, onResponseWithError } from '../utils';
import { API } from '../utils/app.constants';
import { SstoreResponse } from '../utils/parser.utils';
import { Merchants, MerchantPayload, Merchant } from './merchant.models';

@Injectable({
  providedIn: 'root'
})
export class MerchantsService {

  constructor(private appService: AppConfigService, public http: HttpClient) {
  }

  get() {
    const url = this.appService.getUrl(API.MERCHANTS);

    return this.http.get<SstoreResponse<Merchants>>(url)
      .pipe(
        map((raw: SstoreResponse<Merchants>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  getById(id: number | string) {
    const url = this.appService.getUrl(`${API.MERCHANTS}/${id}`);

    return this.http.get<SstoreResponse<Merchant>>(url)
      .pipe(
        map((raw: SstoreResponse<Merchant>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  save(body: MerchantPayload, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.MERCHANTS);
    return this.http.post<void>(url, body, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }

  delete(id: number | string, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(`${API.MERCHANTS}/${id}`);

    return this.http.delete<void>(url, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }
}
