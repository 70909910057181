import { Component } from '@angular/core';
import { AuthService } from '@coreservices/index';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteHistoryService } from './core/services/route-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public translate: TranslateService, private authSrv: AuthService, private router: Router, private routeHistory: RouteHistoryService) {
    const selectedLanguage = localStorage.getItem('Language') ? localStorage.getItem('Language') : "de";
    localStorage.setItem('Language', selectedLanguage);
    this.translate.getTranslation('en');
    this.translate.getTranslation('de');
    this.translate.use(selectedLanguage);
    setTimeout(() => {
      this.getAppVersion();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routeHistory.setPreviousUrl(this.router.url);
      }
    });
  }

  getAppVersion() {
    this.authSrv.getVersion().subscribe((response: any) => {
      console.log(response);
      this.translate.set('api_version', `(${response.version})`, 'en');
      this.translate.set('api_version', `(${response.version})`, 'de');
    }, (error) => {
      console.log(error);
    })
  }
}
