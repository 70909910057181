import { environment } from './../../../environments/environment';
import { ThemeOptions } from './../../theme-options';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  appversion = environment.appVersion;
  constructor(private router: Router, private globals: ThemeOptions, private formBuilder: FormBuilder,
    private authService: AuthenticationService) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  async ngOnInit() {
    if (localStorage.getItem('email') && localStorage.getItem('password')) {
      this.loginForm.value.email = localStorage.getItem('email');
      var decodedString = localStorage.getItem('password') ? JSON.parse(atob(localStorage.getItem('password'))) : '';
      this.loginForm.value.password = decodedString;
      this.loginUser()
    }
  }

  loginUser(): void {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.authService.loginUser(email, password);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  onLoginFormSubmit() {
    this.router.navigate(['/main']);
    this.globals.toggleSidebar = false;
  }

  merchantLogin() {
    localStorage.setItem('user', 'merchant');
    this.globals.toggleSidebar = true;
    this.router.navigate(['/main/store']);
  }
}
