import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { DirectusService } from './directus.service';
import { config } from 'src/environments/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  production = environment.env === 'prod';
  constructor(private directusService: DirectusService, private notifier: NotifierService) {

  }

  errorHandling(err): void {
    this.notifier.notify('danger', err.message);
  }

  sendMail(param) {
    return this.directusService.api.api.post(config.Directus.Api_Url + config.api.SendMail, param).catch(err => this.errorHandling(err));
  }

  createSession() {
    return this.directusService.api.api.get(config.Directus.Api_Url + config.api.CreateSession, { production: this.production }).catch(err => this.errorHandling(err));
  }

  createProduct(param) {
    return this.directusService.api.api.get(config.Directus.Api_Url + config.api.AddNewProduct, param).catch(err => this.errorHandling(err));
  }

  calculateRate(param) {
    return this.directusService.api.api.post(config.Directus.Api_Url + config.api.calculateRate, param).catch(err => this.errorHandling(err));
  }

  sendApplicationData(param) {
    return this.directusService.api.api.post(config.Directus.Api_Url + config.api.SendMailApplication, param).catch(err => this.errorHandling(err));
  }
}
