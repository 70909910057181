import { RouteHistoryService } from 'src/app/core/services/route-history.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, JwtPayload } from '@coreservices/auth';
import { MerchantsService } from '@coreservices/merchants';
import { objLowerCaseParser } from '@coreservices/utils';
import { NotifierService } from 'angular-notifier';
import { ThemeOptions } from 'src/app/theme-options';
import { AppDataService } from './app-data.service';
import { ActivityReportService } from '@coreservices/activity-report';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isLoggedIn = false;
  public user: any = {};
  lst_project: any = [];
  user_profile: any;
  constructor(private router: Router, private notifier: NotifierService, private routeHistory: RouteHistoryService,
    private globals: ThemeOptions, public authSrv: AuthService, public merchantsSrv: MerchantsService,
    private appDataSrv: AppDataService, private activityServ: ActivityReportService
  ) { }

  loginUser(email, password): void {
    const req_data: JwtPayload = {
      username: email,
      password: password
    }
    // this.notifier.hideAll();
    this.notifier.notify('info', 'Logging in.', 'Logging');
    this.authSrv.login(req_data).subscribe((data: any) => {
      this.user = objLowerCaseParser(data.user);
      this.isLoggedIn = true;
      this.notifier.hide('Logging');
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(this.user));
      localStorage.setItem('id', this.user.id);
      localStorage.setItem('first_name', this.user.first_name);
      localStorage.setItem('last_name', this.user.last_name);
      var encodedPassword = btoa(JSON.stringify(password));
      localStorage.setItem('email', email);
      localStorage.setItem('password', encodedPassword);
      if (this.user.is_super_admin && this.user.is_super_admin == '1') {
        console.log("admin login")
        this.routeHistory.resetPreviousUrl();
        this.router.navigate(['/main']);
        this.globals.toggleSidebar = false;
        // this.notifier.notify('success', 'Login Successful.');
        this.appDataSrv.showNotifier('success', 'login_succesful');
      } else {
        this.merchantsSrv.getById(this.user.id_merchant).subscribe((data) => {
          const merchant: any = objLowerCaseParser(data);
          if (merchant && merchant.id && merchant.status == 1) {
            localStorage.setItem('merchantId', merchant.id)
            localStorage.setItem('merchantName', merchant.name);
            if (merchant && merchant.files.length > 0) {
              localStorage.setItem('merchantLogo', merchant.files[0].FULL_PATH);
            }
            localStorage.setItem('role', 'merchant');
            localStorage.setItem('merchant_lief_gpnr', merchant.lief_gpur);
            localStorage.setItem('merchantStatus', merchant.status);
            this.routeHistory.resetPreviousUrl();
            this.router.navigate(['/main/store']);
            this.globals.toggleSidebar = true;
            // this.notifier.notify('success', 'Login Successful.');
            this.appDataSrv.showNotifier('success', 'login_succesful');
            this.activityServ.record('merchant_has_logged_in');
          } else {
            if (merchant && merchant.status == 0) {
              this.notifier.hideOldest();
              this.notifier.notify('warning', 'Merchant is not active.');
            }
            else {
              this.notifier.hideOldest();
              this.notifier.notify('warning', 'Unauthorised user');
            }
          }
        }, (error: any) => {
          console.log(error);
          this.notifier.notify('danger', error.message);
        })
      }
    }, (err) => {
      this.notifier.hide('signing');
      this.notifier.hide('Logging');
      this.notifier.notify('danger', err.message);
    });
  }

  isAuthenticated(): boolean {
    const token: any = localStorage.getItem('token');
    return token && this.isLoggedIn;
  }

  logout(): void {
    this.authSrv.logOut({}).subscribe((data: any) => {
      this.handleLogout();
      console.log(data);
    }, error => {
      this.handleLogout();
      console.log(error);
    })

  }

  handleLogout() {
    this.isLoggedIn = false;
    var language = localStorage.getItem('Language');
    localStorage.clear();
    localStorage.setItem('Language', language);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigateByUrl('/login');
  }

  changePassword(obj) {
    return new Promise((resolve, reject) => {
      this.authSrv.resetPassword(obj).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject(error);
      })
    })
  }
}
