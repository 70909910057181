import { TrustUrlPipe } from './trust-url.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemLanguagesPipe } from './system-languages.pipe';
import { DecimalPipe } from './decimal.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { MomentDatePipe } from './momentDate.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [SystemLanguagesPipe, TrustUrlPipe, DecimalPipe, StripHtmlPipe, MomentDatePipe],
    exports: [SystemLanguagesPipe, TrustUrlPipe, DecimalPipe, StripHtmlPipe, MomentDatePipe],
    providers: [SystemLanguagesPipe]
})
export class PipeModule { }
