import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorResourceStatus, ResourceStatus } from './resource-status';

const throwWithResourcePayload = (resp: HttpErrorResponse, data: any): Observable<any> => {
  const error = new ResourceStatus().setCode(resp.status).setMessage(data.message).setResponse(data);

  return throwError(error);
};

export const onResponseWithError = (resp: HttpErrorResponse): Observable<ErrorResourceStatus> => {
  return throwWithResourcePayload(resp, resp.error);
};