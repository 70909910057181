<div class="app-wrapper min-vh-100">
    <div class="app-main">
        <div class="app-content p-0">
            <div class="app-content--inner d-flex align-items-center">
                <div class="flex-grow-1 w-100 d-flex align-items-center">
                    <div class="bg-composed-wrapper--content py-3">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5 col-sm-12 d-lg-flex align-items-center res-logo">
                                    <img alt="..." class="w-100 mx-auto d-block img-fluid"
                                        src="assets/images/stock-logos/Sleasing_logo.gif">
                                </div>
                                <div class="col-lg-7 col-sm-12 d-flex align-items-center">
                                    <div class="pl-0 pl-md-5 w-100">
                                        <div class="text-black mt-5">
                                            <span class="text-left text-sm-center">
                                                <h1
                                                    class="display-3 text-lg-left text-center mb-3 font-weight-bold login-heading">
                                                    s-Store Login
                                                </h1>
                                            </span>
                                            <div>
                                                <form (ngSubmit)="loginUser()" [formGroup]="loginForm"
                                                    novalidate="novalidate">
                                                    <div class="form-group">
                                                        <label>{{'email' | translate}}</label>
                                                        <input class="form-control" placeholder="yourname@yourmail.com"
                                                            type="email" formControlName="email">
                                                        <small class="form-text text-danger"
                                                            *ngIf="isControlHasError('email','required')">{{'this_field_is_required'
                                                            | translate}} </small>
                                                        <small class="form-text text-danger"
                                                            *ngIf="isControlHasError('email','email')">{{'invalid_email_address'
                                                            | translate}}
                                                        </small>
                                                    </div>
                                                    <div class="form-group mb-4">
                                                        <div class="d-flex justify-content-between">
                                                            <label>{{'password' | translate}}</label>
                                                        </div>
                                                        <input class="form-control" placeholder="**********"
                                                            type="password" formControlName="password">
                                                        <small class="form-text text-danger"
                                                            *ngIf="isControlHasError('password','required')">{{'this_field_is_required'
                                                            | translate}}</small>
                                                    </div>

                                                    <button class="btn btn-lg btn-second btn-block"
                                                        [disabled]="loginForm.invalid" type="submit">{{'login' |
                                                        translate}}</button>
                                                    <!-- <button class="btn btn-lg btn-second btn-block" (click)="merchantLogin()">Merchant Login</button> -->
                                                </form>
                                            </div>
                                            <!-- <div class="text-center pt-4 text-black-50">Don't have an account? <a
                                                    [routerLink]="['/pages-register']">Create an Account</a></div> -->

                                            <div class="text-center pt-4 text-black-50">
                                                <button class="btn m-2 btn-link btn-link-primary" type="button"
                                                    (click)="helpModal.show()"><span>{{'help' |
                                                        translate}}</span></button>
                                                <div class="flex-fill">{{'version' | translate}} | {{appversion}} {{'api_version' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div bsModal #helpModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{'help'}}</h6>
                <button type="button" class="close" aria-label="Close" (click)="helpModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'help_text' | translate}}</p>
            </div>
        </div>
    </div>
</div>