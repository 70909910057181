import { Injectable } from '@angular/core';
import DirectusSDK from '@directus/sdk-js';
import { config } from 'src/environments/config';

@Injectable({
  providedIn: 'root'
})
export class DirectusService {
  private directusClient = new DirectusSDK({
    url: config.Directus.url,
    project: config.Directus.project,
    storage: window.localStorage
  } as any);

  constructor() { }

  public get api(): DirectusSDK {
    return this.directusClient;
  }
}
