import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StateModule } from '@coreservices/state.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FeatherModule } from 'angular-feather';
import { ModalModule, PopoverModule, TabsModule } from 'ngx-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountUpModule } from 'countup.js-angular2';
import { NgxGaugeModule } from 'ngx-gauge';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BsDropdownModule } from 'ngx-bootstrap';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { QRCodeModule } from 'angularx-qrcode';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NouisliderModule } from 'ng2-nouislider';
import { DragulaModule } from 'ng2-dragula';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SstoreAuthInterceptor } from '@coreservices/auth/auth.interceptor';
import { AvoidKeysDirective } from '../core/directives/avoidKeysdirective';
import { NumericInputDirective } from '../core/directives/numeric-input.directive';
import { CurrencyInputDirective } from '../core/directives/currency-input.directive'


import { SidebarComponent } from 'src/app/layout-components/sidebar/sidebar.component';
import { HeaderComponent } from 'src/app/layout-components/header/header.component';
import { FooterComponent } from 'src/app/layout-components/footer/footer.component';
import { SidebarMenuComponent } from 'src/app/layout-components/sidebar-menu/sidebar-menu.component';
import { SidebarFooterComponent } from 'src/app/layout-components/sidebar-footer/sidebar-footer.component';
import { SidebarHeaderComponent } from 'src/app/layout-components/sidebar-header/sidebar-header.component';
import { SidebarUserboxComponent } from 'src/app/layout-components/sidebar-userbox/sidebar-userbox.component';
import { HeaderDotsComponent } from 'src/app/layout-components/header-dots/header-dots.component';
import { HeaderUserboxComponent } from 'src/app/layout-components/header-userbox/header-userbox.component';
import { HeaderDrawerComponent } from 'src/app/layout-components/header-drawer/header-drawer.component';
import { PipeModule } from '../core/pipes/pipe.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false
};

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');
}

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 30000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AvoidKeysDirective,
    NumericInputDirective,
    CurrencyInputDirective,

    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    SidebarHeaderComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    SidebarUserboxComponent,
    HeaderDotsComponent,
    HeaderUserboxComponent,
    HeaderDrawerComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StateModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    AutocompleteLibModule,
    FeatherModule,
    PopoverModule,
    TabsModule,
    ModalModule.forRoot(),
    NgApexchartsModule,
    CountUpModule,
    NgxGaugeModule,
    PipeModule,
    BsDropdownModule,
    Ng2SmartTableModule,
    UiSwitchModule,
    NgSelectModule,
    DropzoneModule,
    SweetAlert2Module,
    QRCodeModule,
    Ng2SearchPipeModule,
    NouisliderModule,
    DragulaModule.forRoot(),
    TranslateModule.forChild({
      defaultLanguage: localStorage.getItem('Language') ? localStorage.getItem('Language') : 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StateModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    AutocompleteLibModule,
    FeatherModule,
    PopoverModule,
    TabsModule,
    ModalModule,
    NgApexchartsModule,
    CountUpModule,
    NgxGaugeModule,
    PipeModule,
    BsDropdownModule,
    Ng2SmartTableModule,
    UiSwitchModule,
    NgSelectModule,
    DropzoneModule,
    SweetAlert2Module,
    QRCodeModule,
    Ng2SearchPipeModule,
    NouisliderModule,
    TranslateModule,
    DragulaModule,

    AvoidKeysDirective,
    NumericInputDirective,
    CurrencyInputDirective,

    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    SidebarHeaderComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    SidebarUserboxComponent,
    HeaderDotsComponent,
    HeaderUserboxComponent,
    HeaderDrawerComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SstoreAuthInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ]
})
export class SharedModule {
  constructor() { }
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
