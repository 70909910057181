import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value
      .replace(/<.*?>/g, '')
      .replace('&auml;', 'ä')
      .replace('&Auml;', 'Ä')
      .replace('&eacute;', 'é')
      .replace('&ouml;', 'ö')
      .replace('&Ouml;', 'Ö')
      .replace('&uuml;', 'ü')
      .replace('&Uuml;', 'Ü')
      .replace('&szlig;', 'ß');
  }
}
