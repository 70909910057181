import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeOptions } from './theme-options';

// Widgets

// NGX Bootstrap Core

// NGX Bootstrap Buttons

import { ButtonsModule } from 'ngx-bootstrap';

// NGX Bootstrap Dropdown

import { BsDropdownModule } from 'ngx-bootstrap';

// NGX Bootstrap Datepicker

// import { BsDatepickerModule } from 'ngx-bootstrap';// -commented Rajiv Jha

// NGX Bootstrap Modal

import { ModalModule } from 'ngx-bootstrap';

// NGX Bootstrap Pagination

import { PaginationModule } from 'ngx-bootstrap';

// NGX Bootstrap Progress bar

import { ProgressbarModule } from 'ngx-bootstrap';

// NGX Bootstrap Tabs

import { TabsModule } from 'ngx-bootstrap';

// NGX Bootstrap Popover

import { PopoverModule } from 'ngx-bootstrap';

// NGX Bootstrap Tooltip

import { TooltipModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

// NG2 File Upload

// import { FileUploadModule } from 'ng2-file-upload';
import { NgxUiLoaderModule } from "ngx-ui-loader";
// NGX Dropzone for Angular

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: null,
  maxFilesize: 256,
  acceptedFiles: 'image/*',
  autoProcessQueue : false,
  timeout: 180000,
  headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
};

// NG2 Table

import { Ng2TableModule } from 'ng2-table';

// NG2 Smart Table

import { Ng2SmartTableModule } from 'ng2-smart-table';

// NG2 Completer

import { Ng2CompleterModule } from 'ng2-completer';

// NG Select

import { NgSelectModule } from '@ng-select/ng-select';

// NGX Autosize

import { AutosizeModule } from 'ngx-autosize';

// Angular Feather Icons

import { FeatherModule } from 'angular-feather';
import {
  Calendar,
  Activity,
  Bell,
  Settings,
  Search,
  Grid,
  Users,
  LifeBuoy,
  CloudDrizzle,
  Coffee,
  Box,
  Briefcase,
  Layers,
  Printer
} from 'angular-feather/icons';
const icons = {
  Calendar,
  Activity,
  Bell,
  Settings,
  Search,
  Grid,
  Users,
  LifeBuoy,
  CloudDrizzle,
  Coffee,
  Box,
  Briefcase,
  Layers,
  Printer
};

// FontAwesome Regular SVG Icons

import {
  faSquare,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faLifeRing,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from '@fortawesome/free-regular-svg-icons';

// FontAwesome Solid SVG Icons

import {
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkerAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';

// FontAwesome Brand SVG Icons

import {
  faFacebook,
  faTwitter,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from '@fortawesome/free-brands-svg-icons';

// Angular FontAwesome Icons

import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome';

// // Angular Progressbar Core

import { NgProgressModule } from '@ngx-progressbar/core';

// // Angular Progressbar router module

import { NgProgressRouterModule } from '@ngx-progressbar/router';


// NGX Spinner

import { NgxSpinnerModule } from 'ngx-spinner';

// Angular SweetAlerts2 Notifications

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// SweetAlerts2 Notifications

// Angular Notifier

import { NotifierModule, NotifierOptions } from 'angular-notifier';
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

// NGX Pagination for Angular

import { NgxPaginationModule } from 'ngx-pagination';
import { LoginComponent } from './pages/login/login.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { PipeModule } from './core/pipes/pipe.module';
import { DecimalPipe } from './core/pipes/decimal.pipe';
import { SharedModule } from './shared/shared.module';
import { StateModule } from '@coreservices/state.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { API } from '@coreservices/utils/app.constants';
import { MomentDatePipe } from './core/pipes/momentDate.pipe';

// export function translateLoaderFactory(httpClient: HttpClient) {
//   localStorage.getItem('Language') ? localStorage.getItem('Language') : localStorage.setItem('Language', 'de');
//   return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');
// }

export function translateLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

@NgModule({
  declarations: [
    AppComponent,
    // Non Template pages
    LoginComponent,

  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    StateModule,
    SharedModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    DropzoneModule,
    Ng2TableModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    NgSelectModule,
    AutosizeModule,
    FeatherModule.pick(icons),
    FontAwesomeModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    NgxPaginationModule,
    NgxUiLoaderModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('Language') ? localStorage.getItem('Language') : 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  exports: [TranslateModule],
  providers: [
    DecimalPipe,
    MomentDatePipe,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    ThemeOptions,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faFacebook,
      faPrint,
      faAlignCenter,
      faMapMarkerAlt,
      faTachometerAlt,
      faExternalLinkAlt,
      faShareSquare,
      faSitemap,
      faInfoCircle,
      faLifeRing,
      faTwitter,
      faQuoteRight,
      faStarHalfAlt,
      faSync,
      faShapes,
      faCarBattery,
      faTable,
      faCubes,
      faPager,
      faAngular,
      faVuejs,
      faReact,
      faHtml5,
      faCheckCircle,
      faTimesCircle,
      faBomb,
      faNetworkWired,
      faBusAlt,
      faBirthdayCake,
      faEyeDropper,
      faThumbsUp,
      faCameraRetro,
      faUnlockAlt,
      faDownload,
      faUpload,
      faReply,
      faGoogle,
      faFileImage,
      faFolderOpen,
      faBars,
      faTrashAlt,
      faSave,
      faPlayCircle,
      faEllipsisV,
      faEllipsisH,
      faSlidersH,
      faFileArchive,
      faAward,
      faCaretRight,
      faInstagram,
      faPinterest,
      faYoutube,
      faDiscord,
      faSlack,
      faDribbble,
      faGithub,
      faPlus,
      faFolder,
      faTimes,
      faEnvelope,
      faAddressCard,
      faMap,
      faCalendarAlt,
      faImages,
      faFilm,
      faClock,
      faSearch,
      faChevronRight,
      faChevronUp,
      faChevronLeft,
      faChevronDown,
      faLink,
      faLightbulb,
      faGem,
      faCog,
      faDotCircle,
      faArrowsAltH,
      faComments,
      faCommentDots,
      faKeyboard,
      faObjectGroup,
      faUser,
      faUserCircle,
      faQuestionCircle,
      faBuilding,
      faBell,
      faFileExcel,
      faFileAudio,
      faFileVideo,
      faFileWord,
      faFilePdf,
      faFileCode,
      faFileAlt,
      faEye,
      faChartBar,
      faPlusCircle,
      faAngleRight,
      faAngleUp,
      faAngleLeft,
      faAngleDown,
      faArrowUp,
      faArrowDown,
      faArrowRight,
      faArrowLeft,
      faStar,
      faSignOutAlt,
      faLemon
    );
  }
}
