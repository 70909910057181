import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth';
import { AppConfigService } from './app-config';
import { ProductGroupsService } from './product-groups';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SstoreAuthInterceptor } from './auth/auth.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    AppConfigService,
    ProductGroupsService
  ]
})
export class StateModule { }
