import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@coreservices/app-config';
import { ResourceErrorPayload, SstoreResponse, onResponseWithError } from '@coreservices/utils';
import { API } from '@coreservices/utils/app.constants';
import { catchError, map } from 'rxjs/internal/operators';
import { Activities, ActivityPayload } from './activity-report.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityReportService {

  constructor(private appService: AppConfigService, public http: HttpClient) { }

  get() {
    const url = this.appService.getUrl(API.ACTIVITYREPORT);
    return this.http.get<SstoreResponse<Activities>>(url)
      .pipe(
        map((raw: SstoreResponse<Activities>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  post(body: ActivityPayload, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.REGISTERACTIVITY);
    return this.http.post<void>(url, body, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }

  record(message, payload: any = null) {
    const merchant_id = localStorage.getItem('merchantId');
    const action_payload = {
      user_id: merchant_id,
      action: message,
    }
    if (payload) {
      action_payload['details'] = {
        product_id: payload.product_id ? payload.product_id : payload.id,
        price: payload.price,
        status: payload.status ? payload.status : ''
      }
    }
    this.post(action_payload).subscribe((res) => {
      console.log('activity successres: ', res);
    }, (error) => {
      console.log('activity error: ', error);
    })
  }
}
