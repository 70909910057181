import { Injectable } from '@angular/core';
import { DirectusService } from './directus.service';
import { BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { NotifierService } from 'angular-notifier';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { config } from 'src/environments/config';
import { StripHtmlPipe } from '../pipes/strip-html.pipe';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  currentLoading = null;
  userInfo: any;
  token: any;
  languageList: any;
  public systemLanguages = [];
  languageChange = new BehaviorSubject(false);

  constructor(
    private directusService: DirectusService, private notifier: NotifierService,
    private loader: NgxUiLoaderService,
    private stripHtmlPipe: StripHtmlPipe, private userservice: UserService,
    public translateSrv: TranslateService
  ) { }

  transform(str) {
    const currentLang = this.translateSrv.currentLang; // get current language
    const returnValue = this.translateSrv.translations[currentLang][str]; // get converted string from current language
    if (returnValue === undefined) {
      if(this.translateSrv.translations.en_merch && this.translateSrv.translations.en_merch[str] !== undefined) {
        return this.translateSrv.translations.en_merch[str]; // if value is getting undefined then return default language string, en_merch is default english language file here
      } else {
        return str;
      }
    } else {
      return returnValue;
    }
  }

  translateByLang(str, lang) {
    const returnValue = this.translateSrv.translations[lang][str]; // get converted string from current language
    if (returnValue === undefined) {
      return this.translateSrv.translations.en_merch[str]; // if value is getting undefined then return default language string, en_merch is default english language file here
    } else {
      return returnValue;
    }
  }

  showNotifier(type, message, id?) {
    try {
      var msg = this.transform(message);
      this.notifier.notify(type, this.stripHtmlPipe.transform(msg));
    } catch (error) {

    }
  }

  hideNotifier(id?) {
    if (id) {
      this.notifier.hide(id);
    } else {
      this.notifier.hideAll();
    }
  }

  getUser() {
    return this.directusService.api.getUser(15);
  }

  getCurrentUser() {
    return this.directusService.api.getMe();
  }

  resetPassword(password, token) {
    return this.directusService.api.resetPassword(password, token);
  }

  geCurrentLoggedIntUserRole() {
    return from(this.directusService.api.getMe({ fields: '*.*' }))
      .pipe(
        map((userInfo) => {
          return userInfo.data.role.name;
        })
      )
      .toPromise();
  }

  createUser(data) {
    return this.directusService.api.api.post('users', data);
  }

  updateUser(id, obj) {
    return this.directusService.api.updateUser(id, obj);
  }

  getAllCollections() {
    return this.directusService.api.getCollections();
  }

  getData(collectionName, id?, fields?, sort?, filter?) {
    let params = { fields: '*.*.*.*' };
    if (fields) {
      Object.assign(params, { fields: fields });
    }
    if (sort) {
      Object.assign(params, { sort: sort });
    }
    if (filter) {
      Object.assign(params, { filter: filter });
    }
    if (id) {
      return this.directusService.api.getItem(collectionName, id, params);
    } else {
      return this.directusService.api.getItems(collectionName, params);
    }
  }

  getSingleData(collectionName, id) {
    return this.directusService.api.getItem(collectionName, id, { fields: '*.*.*' });
  }

  getCollectionData(collectionName) {
    return this.directusService.api.getCollection(collectionName);
  }

  getFile(id) {
    return this.directusService.api.api.get('files/' + id);
  }

  uploadFile(file) {
    console.log(file)
    const formData = new FormData();
    formData.append('data', file);
    return this.directusService.api.api.post('files', formData);
  }

  uploadFileCustom(file) {
    console.log(file)
    const formData = new FormData();
    formData.append('data', file);
    return this.directusService.api.api.post(config.Directus.url + '/' + config.Directus.project + '/files', formData).catch(err => this.errorHandling(err));
  }

  deleteFile(id) {
    return this.directusService.api.api.delete('files/' + id);
  }

  async presentLoader() {
    this.loader.start();
  }

  async dismissLoader() {
    this.loader.stop();
  }

  addNewItem(collection, body) {
    return this.directusService.api.createItem(collection, body);
  }

  calculateRate(body) {
    return this.userservice.calculateRate(body).then((res: any) => {
      this.showNotifier('success', 'Success');
      return res;
    });
  }

  updateItem(collection, primaryKey, data) {
    return this.directusService.api.updateItem(collection, primaryKey, data);
  }

  deleteItem(collection, id) {
    return this.directusService.api.deleteItem(collection, id);
  }

  async getSystemLanguagesData(collection) {
    if (this.languageList && this.languageList.length > 0) {
      this.systemLanguages.push(this.languageList);
    } else {
      const temp: any = await this.directusService.api.api.get(config.Directus.url + '/' + config.Directus.project + '/items/system_languages', { fields: '*.*.*.*', limit: 1000 });;
      this.languageList = temp.data;
      this.systemLanguages.push(temp.data);
      // this.systemLanguageSrv.get().subscribe((result: SysLanguages) => {
      //   const data: SysLanguages = result.map((item: SysLanguage) => {
      //     item = objLowerCaseParser(item);
      //     return item;
      //   });
      //   this.languageList = data;
      //   this.systemLanguages.push(data);
      // }, error => {
      //   this.showNotifier('danger', 'Failed to fetch data, please try again!');
      //   console.log(error);
      // });
    }
  }

  async getproductDetail(id) {
    const temp: any = await this.directusService.api.api.get(config.Directus.url + '/' + config.Directus.project + '/items/product/' + id, { fields: '*.*.*', limit: 600 });;
    return temp.data;
  }

  async getcollectiondatawithouttoken(collectionname) {
    const temp: any = await this.directusService.api.api.get(config.Directus.url + '/' + config.Directus.project + '/items/' + collectionname, { fields: '*.*' });;
    return temp.data;
  }

  async getDropdowndata(id) {
    const temp: any = await this.directusService.api.api.get(config.Directus.url + '/' + config.Directus.project + '/items/dropdown', { fields: '*.*', limit: 600, filter: { 'title': id } });;
    return temp;
  }

  getUserDeviceToken(collection, token) {
    return this.directusService.api.getItems(collection, {
      fields: '*.*',
      filter: {
        device_token: token
      }
    });
  }

  getToken(token) {
    return this.directusService.api.getItems('device_token_notification', {
      fields: '*.*',
      filter: {
        device_token: token
      }
    });
  }

  getUserLog(collection, userid) {
    return this.directusService.api.getItems(collection, {
      fields: '*',
      filter: {
        user_id: userid
      }

    });
  }

  isLoggedIn() {
    return this.directusService.api.isLoggedIn();
  }

  //To add form data in collection
  addData(collection, param) {
    return this.directusService.api.api.post(config.Directus.url + '/' + config.Directus.project + '/items/' + collection, param).catch(err => this.errorHandling(err));
  }

  errorHandling(err): void {
    this.notifier.notify('danger', err.message);
  }

  showMessage(type, message) {
    try {
      this.notifier.notify(type, this.stripHtmlPipe.transform(message));
    } catch (error) {
      console.log('error: ', error);
    }
  }
}
