import { Pipe, PipeTransform, ChangeDetectorRef, Optional } from '@angular/core';

@Pipe({
  name: 'systemLanguages',
  pure: false
})
export class SystemLanguagesPipe implements PipeTransform {
  constructor() { }
  transform(systemLanguage: any[], key: string): string {
    let result = '';
    key = key.replace(/ /g, "_").toLowerCase();
    if (!localStorage.getItem('Language')) {
      localStorage.setItem('Language', 'de')
    }
    if (systemLanguage) {
      // console.log(systemLanguage)
      systemLanguage.filter((sl) => {
        // console.log(sl.key, key)
        if (sl.key === key) {
          result = sl.translations.find((obj) => obj.language == (localStorage.getItem('Language') ? localStorage.getItem('Language') : 'de')).text;
        }
      });
    }
    return result;
  }
}