import { environment } from './environment';

export const config = {
  Directus: {
    url: 'https://backend.dexycon-api.com/sstore/proxy',
    project: environment.directus_project,
    test: true,
    Api_Url: "https://backend.dexycon-api.com",
    // Api_Url: "http://localhost:20099"
  },
  api: {
    SendMail: '/sstore/SendMail?env=' + environment.env,
    CreateSession: '/sstore/CreateSession',
    AddNewProduct: '/sstore/AddNewProduct',
    calculateRate: '/sstore/recalculate-rates',
    addData: '/sstore/items/',
    SendMailApplication: '/sstore/MailnewLandingPage?env=' + environment.env
  },
  collection: {
    module: 'system_modules',
    user_profile: 'user_profile',
    directus_users: 'directus_users',
    roles: 'directus_roles'
  }
};
