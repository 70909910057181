import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/internal/operators';
import { AppConfigService } from '../app-config';
import { ResourceErrorPayload, onResponseWithError } from '../utils';
import { API } from '../utils/app.constants';
import { SstoreResponse } from '../utils/parser.utils';
import { ProductGroups, ProductGroupPayload } from './product-groups.models';


@Injectable({
  providedIn: 'root'
})
export class ProductGroupsService {

  constructor(private appService: AppConfigService, public http: HttpClient) {
  }

  get() {
    const url = this.appService.getUrl(API.PRODUCT_GROUPS);

    return this.http.get<SstoreResponse<ProductGroups>>(url)
      .pipe(
        map((raw: SstoreResponse<ProductGroups>) => raw.data),
        catchError(onResponseWithError)
      );
  }

  save(body: ProductGroupPayload, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(API.PRODUCT_GROUPS);
    return this.http.post<void>(url, body, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }

  delete(id: number | string, headers?: HttpHeaders): Observable<void | ResourceErrorPayload> {
    const url = this.appService.getUrl(`${API.PRODUCT_GROUPS}/${id}`);

    return this.http.delete<void>(url, { headers })
      .pipe(
        catchError(onResponseWithError)
      );
  }
}
