import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() {
  }

  getBaseUrl(url: string) {
    return `${environment.api_url}/${url}`;
  }

  getUrl(url: string) {
    return `${environment.api_url}/api/v1/sstore/${url}`;
  }

  getApiUrl(url: string) {
    return `${environment.api_url}/api/${url}`;
  }

  getOldUrl(url: string) {
    return `${environment.api_url}/api/v1/project/null/${url}`;
  }

  isApiCall(request: HttpRequest<any>): boolean {
    return !!(request.url.indexOf(environment.api_url) === 0);
  }

}




